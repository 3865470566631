import {useCourseCompletedTaskQuery} from 'src/graphql-generated'
import useViewer from 'src/hooks/useViewer'

export const useContentfulCompletedTasks = (taskId: string) => {
  const {loggedIn} = useViewer()
  const {data, refetch} = useCourseCompletedTaskQuery({
    variables: {taskId: taskId},
    skip: !loggedIn,
  })

  if (loggedIn) {
    refetch()
  }

  return data?.contentfulCompletedTaskSteps
}
