import {graphql} from 'gatsby'
import React from 'react'

import {Box, Flex} from 'src/components/Box'
import FeaturedCarousel from 'src/components/Course/FeaturedCarousel'
import SimpleCarousel from 'src/components/Course/SimpleCarousel'
import Layout from 'src/components/Layout'
import {GetSchooledIcon} from 'src/components/icons'
import {Body, HeaderXl} from 'src/components/text'
import * as breakpoints from 'src/styles/mediaQuery'

export const query = graphql`
  query Courses {
    allContentfulTasks {
      nodes {
        ...CourseFragment
      }
    }
  }
`

const Courses = ({data}: any) => {
  const items = data.allContentfulTasks.nodes.map((item: any) => ({
    stepCount: item.steps.length,
    ...item,
    steps: item.steps.map((step: any) => ({
      ...step,
      description: step.description?.description || '',
    })),
    description: item.description?.description || '',
  }))
  return (
    <Layout overflow="hidden">
      <Flex flexDirection="row" alignItems="center" mt={4}>
        <GetSchooledIcon
          width={{default: 38, md: 200, lg: 205}}
          height={{default: 38, md: 200, lg: 205}}
          css={{
            display: 'none',
            [breakpoints.medium]: {
              display: 'block',
            },
          }}
        />
        <Flex
          width={{lg: '75%'}}
          gridGap={2}
          flexDirection="column"
          css={{margin: '0 auto 0 auto'}}
        >
          <HeaderXl fontSize={{default: '28px', md: '2rem', lg: '2.75rem'}}>
            Get Schooled Courses
          </HeaderXl>
          <Box display={{md: 'block', default: 'none'}}>
            <Body>
              Welcome to our virtual learning courses! Each course is crafted to
              provide a dynamic learning experience through a blend of articles,
              videos, and activities tailored to your specific needs.
            </Body>
          </Box>
        </Flex>
      </Flex>
      <FeaturedCarousel title="Featured Courses" data={items} />
      <SimpleCarousel title="Apply to College" data={items} />
    </Layout>
  )
}

export default Courses
